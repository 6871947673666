//MODULE-PUFF-2 ATA-------------------------------------------------------
	//.bg-white.module-puff-2,
	.module-puff-2,
	.module-puff-3,
	.module-puff-4{
		//padding: 2em 0 0 0;
		//padding: 1em 0 0 0; //2015-06-22
		overflow: hidden;

		//tar bort eftersom jag lägger till margin-bottom på li i module-puff-x-list 2017-06-29
		/*.wrapper-1150,
		//.wrapper-1158
		.wrapper-1920{
			padding-bottom: 1em;
		}*/

		//se till så att dessa inte får margin-bottom eftersom de redan ligger i .wrapper-1150 med padding-bottom om de finns i .module-puff-X
		.colly-mixedboxes--blue,
		.colly-mixedboxes--coral,
		.colly-mixedboxes--darkblue,
		.colly-mixedboxes--yellow,
		.colly-mixedboxes--transparent{
			margin-bottom:0;
		}
	}

		//lista för puffarna
		.module-puff-1-list,
		.module-puff-2-list,
		.module-puff-3-list,
		.module-puff-4-list{
			list-style-type: none;
			@include borderbox;
			//padding: 0 1em;
			text-align: center;

			li{
				width: 100%;
				position: relative;
				display: inline-block;
				//margin: 0 auto 1em auto;
				//margin: 0;
                //margin:0 0 1em 0;
                margin: 0;

				@include breakpoint(mobile-large) {
					//3 i bredd
						//width: 31%;
						//margin: 0 3.1% 1em 0;
					float: left;
					width: 50%;
					//margin:0 0 1em 0;	
					//margin:0 0 0.5em 0;

				}
			}//li

			//lägger till att man kan klicka på puffarna
			a{
				text-decoration: none;
			}
		}//module-puff-1-list till 4

		.module-puff-1-list,
		.module-puff-2-list,
		.module-puff-3-list,
		.module-puff-4-list{
			figure{
				margin:0 auto;
			}

			.wrapper{	
				position:relative;
            }

			.hidden-text{
				visibility: hidden;
				position: absolute;
				//top: 0;
				//height: 100%;
				width: 100%;
				background-color: $colly-startpage-darkblue;//$turquise;
                //background-color: rgba(0, 44, 101, 0.9);
                background-color: rgba(4, 41, 103, 0.9);
				color: #FFF;
				opacity: 0;

				bottom:0;
				height: 100%;
				//height:calc(100% - 49px); //55px
				height:calc(100% - 48px); //få bort transparent linje ibland högst upp
				
				  -webkit-transition: visibility 0s linear 0.25s,opacity 0.25s linear;
				  -moz-transition: visibility 0s linear 0.25s,opacity 0.25s linear;
				  -o-transition: visibility 0s linear 0.25s,opacity 0.25s linear;
				  -ms-transition: visibility 0s linear 0.25s,opacity 0.25s linear;	
				  transition:visibility 0s linear 0.25s,opacity 0.25s linear;	
			}

			//olika färg på hidden-text för olika nummer
				.two .hidden-text{
					background-color:$colly-startpage-lightblue;
                    //background-color:rgba(0, 89, 154, 0.9);
                    background-color:rgba(0, 137, 208, 0.9);
				}

				.three .hidden-text{
					background-color: $colly-startpage-darkblue;
                    //background-color: rgba(0, 44, 101, 0.9);
                    background-color: rgba(4, 41, 103, 0.9);
				}

				.four .hidden-text{
					background-color: $colly-startpage-lightblue;
                    //background-color:rgba(0, 89, 154, 0.9);
                    background-color:rgba(0, 137, 208, 0.9);
				}

			.one .wrapper:hover .hidden-text, .one .wrapper:focus .hidden-text,
			.two .wrapper:hover .hidden-text, .two .wrapper:focus .hidden-text,
			.three .wrapper:hover .hidden-text, .three .wrapper:focus .hidden-text,
			.four .wrapper:hover .hidden-text, .four .wrapper:focus .hidden-text{
				visibility: visible;
				opacity:1;
				transition-delay:0s;
			}

			//eftersom figcaption ska synas på Colly istället för att döljas som på ATA


			p, .colly-btn-transparent{ //centers text inuti div
				position: absolute;//relative;
				//text-align: center;
				text-align:left;
			    top: 50%;
			    left: 50%;
			    margin: 0;
			    width: 92%;
  				-webkit-transform: translate(-50.1%, -50.1%);
			  	-ms-transform: translate(-50.1%, -50.1%);
			  	transform: translate(-50.1%, -50.1%);
			  	line-height: 1.33;//1.5;
			  	@include fontSizePX(14);

			    @include breakpoint(computer-small) {
			    	@include fontSizePX(18);
			    	width: 72%;
			    }
            }
            
            .colly-btn-transparent{
                width: auto;
                @include fontSizePX(18);
                //padding:(6/16)+em (10/16)+em;
            }

			//det går inte att ha h2 om man inte vill skriva om mer kod, lägger in titel som ett span i positionerad p
			.hidden-text-title{
				@include fontSizePX(16);
				//border-bottom: 1px solid #FFF;
				//line-height: 1;
				//font-family:'Industry-Book', Arial, Helvetica, sans-serif;
				//display: inline-block;
				//display: inline; //annars kommer border-bottom att bara gälla orden på nedre raden
                @include marginPXtoEM(16,0,0,0.5,0);
                display:block; //ändrar från inline eftersom jag inte tror att border-bottom används på colly startsida, och för att slippa mellanrum orsakat av br (tvungen att ha för icke flexbox) på flexbox.

				@include breakpoint(computer-small) {
					@include fontSizePX(26);
					@include marginPXtoEM(26,0,0,0.5,0);
					//border-bottom: 2px solid #FFF;
				}
			}

			img{
				display: block;
				width: 100%;
				height: auto;
			}

			figcaption{
				color: #FFF;
				width: 100%;
				//@include fontSizePX(26); //2015-06-22
				@include fontSizePX(22.4); //2015-06-22
				//position: absolute;
				position: relative;
				text-align: center;
				//top: 50%;
				//left: 50%;

			    //bottom: 0;
				top: 0;
      			background-color: $colly-startpage-darkblue;
      			line-height: 1.333;
      			//@include paddingPXtoEM(26,0.75,0,0.875,0);
      			@include paddingPXtoEM(22.4,0.75,0,0.875,0);
				@include fontFamily-light;
			}

			//olika färg på bandet för olika nummer på li
			.two figcaption{
				background-color:$colly-startpage-lightblue;
			}

			.three figcaption{
				background-color:$colly-startpage-darkblue;
			}

			.four figcaption{
				background-color: $colly-startpage-lightblue;
			}
		}//module-puff-2-list

		//lägger mittenrubriken längst ner istället

		//på colly vill man vänsterställa texten som visas när man hovrar på vissa boxar
		.module-puff-2-list p, .module-puff-1-list p{
			//text-align: left;
			width: 88%;
    		width: calc(100% - 4em);
		}

		/*.module-puff-3-list p{
			text-align:left;
		}*/

		/*.module-puff-2-list .colly-btn-outline, */.module-puff-1-list .colly-btn-outline{
			display: block;
			margin:1em 0 0 0;
		}

		//module-puff-3-list, .module-puff-3-list--has-space
		//.module-puff-3-list--has-space,
		//.module-puff-4-list--has-space,
		//.module-puff-4-list--plain


		.has-space li{
			@include borderbox;
		}

		.module-puff-1 .colly-mixedboxes, .module-puff-2 .colly-mixedboxes {
			margin-top: 0;
		}

		//positionerar knappen när man hovrar över boxar i .module-puff-4-list
		.module-puff-2-list .colly-btn-outline,
		.module-puff-4-list .colly-btn-outline,
		.module-puff-3-list .colly-btn-outline{
			display: inline-block;
			margin: 1em auto 0;
			text-decoration: none;
        	@include fontSizePX(15); //14
        	@include paddingPXtoEM(15,0.375,1,0.4375,1); //14

			&:hover, &:focus, &:active{
				text-decoration: none;
			}
		}

		@include breakpoint(mobile-large) {
			.module-puff-2-list.has-space li:nth-of-type(odd){ //50 50 med mellanrum
				padding-right: 0.5em;
			}
			.module-puff-2-list.has-space li:nth-of-type(even){
				padding-left:0.5em;
			}

			//om man har en mixad box efter
			.module-puff-1-list li {
				padding-right:0.5em;
			}
			
			//om man har en mixad box före
			.colly-mixedboxes + .module-puff-1-list li{
				padding-left: 0.5em;
				padding-right: 0;
			}
			
		}//mobile-large

		.module-puff-3-list figcaption, .module-puff-4-list figcaption{
			@include fontSizePX(22);
			//@include paddingPXtoEM(22,0.75,0,0.875,0);
			@include paddingPXtoEM(22,0.5,1,0.75,1);
			@include borderbox;
		}

		.module-puff-1-list figcaption, .module-puff-2-list figcaption{ //vänsterställda, större figcaptions på module-puff-2-list som används bl.a. till videos
			@include borderbox;
			text-align: left;
    		@include fontSizePX(24);
			@include paddingPXtoEM(24,1.125,2,1.125,2); //18 32	
			position: relative;
		}

		.module-puff-1-list figcaption span, .module-puff-2-list figcaption span{// länken i figcaption "Alla filmer"
			@include fontSizePX(16);
    		position: absolute;
    		right: 2em;
			top: 0.375em;

			@include breakpoint(computer-small) {
				top: 1.5em;
			}
			
			a{
				color:#FFF;
				text-decoration:none;

				&:hover, &:focus, &:active{
					text-decoration:underline;
				}
			}//a
		}//span

		//.module-puff-4-list--has-space


		@include breakpoint(computer-small) {
			.moule-puff-2-list li{
				width:50%;
			}

			.module-puff-3-list li{
				width: 33.333%;				
			}


			.module-puff-4-list li{
				width: 25%;
			}

			.module-puff-1-list p, .module-puff-2-list p, .module-puff-3-list p{
				@include fontSizePX(16);
			}


			.module-puff-4-list p{
				@include fontSizePX(14);
			}

			.module-puff-1-list .hidden-text-title, .module-puff-2-list .hidden-text-title, .module-puff-3-list .hidden-text-title{
				@include fontSizePX(22);
			}

			
			.module-puff-4-list .hidden-text-title{
				@include fontSizePX(18);
			}
		}//computer-small

