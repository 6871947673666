//SUBMENU2

#toggle:checked ~ nav #nav .submenu .submenu2 li,
#toggle:checked ~ nav #nav .submenu .submenu2 a {
  height: 0;
  line-height: 0;
  overflow: hidden;
  border-bottom:none;
}

#toggle:checked ~ nav #nav .submenu .submenu2.open li,
#toggle:checked ~ nav #nav .submenu .submenu2.open a {
  height: 3em;
  line-height: 3em;
  overflow: visible; //EDENVIK ser till så att första submenylänken är lika bred som de andra
  border-bottom:thin solid $colly-startpage-middleblue; //#949494;
  &:hover{
  //background-color:lighten(#555, 10%);
  background-color: $colly-startpage-lightlightblue; //$colly-yellow;// #FFF;
  color: $colly-startpage-darkblue;
    }
  }

#toggle:checked ~ nav #nav .submenu.open li.submenu-bg{ //make sure the .submenu-bg li height is not 3em, which means the inner submenu2 cannot be taller than 3em
height:auto;
border-bottom:none; //removes double border bottoms
}

#toggle:checked ~ nav #nav .submenu.open li.submenu-bg a.btn-plus{
border-bottom:none;
}

#toggle:checked ~ nav #nav .submenu .submenu-bg a.btn-plus{
display: none;
}

#toggle:checked ~ nav #nav .submenu.open .submenu-bg a.btn-plus{
display: block;
}

#toggle:checked ~ nav #nav .submenu2 a {
  padding-left: 10%;//7%;
  background-color:$colly-startpage-lightblue;//#d3d3d3;
  color: #FFF;
}

#toggle:checked ~ nav #nav li:hover .submenu2 a:hover,
#toggle:checked ~ nav #nav li:hover .submenu2 a:active,
#nav li:hover .submenu2 a:hover,
#nav li:hover .submenu2 a:active{
        color:#FFF; //$colly-yellow;
      }
 

//@media screen and (min-width: 48em){/*här byts mellan mobilnav och vanlig nav
//@include breakpoint(computer-small-tablet) {
@include breakpoint(computer-small) {

#toggle:checked ~ nav #nav .submenu2 li,
#nav .submenu2 li {
  //display: inline;
  //display: inline-block;
  display: block;
  float: none; //left;
  padding-bottom: 0.25em;
  padding-top: 0.25em;
  //padding-right: 2%;
  //@include borderbox;
  //float: right;
}

//sub
#toggle:checked ~ nav #nav .submenu2, //Gör så att toggle för mobile göms på stor skärm
#nav .submenu2 {
  display:none;
  //display: block; Storesupport
}

#toggle:checked ~ nav #nav li li.submenu-bg:hover .submenu2,
#nav li li.submenu-bg:hover .submenu2 {
        display:block;
        position: absolute;
        //padding-top:1.125em;
        background-color: $colly-darkblue;
        background-color: rgba(0, 44, 101, 0.91);
      }

#nav .submenu2{
z-index: -1;
text-align: left;//right;
left:3.8125em;//61px;
padding-left: 0;
//padding-top: 0.35em;//1em;
min-width: 8.0625em;//114px;
//margin-left: -0.0625em;
top: 0;
padding-top: 0.75em;
padding-bottom: 0.75em;
}


    #toggle:checked ~ nav #nav li .submenu2 a,
    #nav li .submenu2 a{
      //@include fontSizePX(12);
      @include fontSizePX(14);
      //text-transform: uppercase;
      color: grey;
      //@include paddingPXtoEM(12,0,0.125,0,0);
      @include paddingPXtoEM(12,0,0.3125,0,0);
      display: block;
      text-align: left;
    }


  #nav .submenu2 a.link-margin-largescreen{margin: 0.3125em 0 -0.3125em 0;color:#333;}

#toggle:checked ~ nav #nav li:hover .submenu2 li,
#nav li:hover .submenu2 li{
  border-left: 0.5em solid #FFF;
}

#toggle:checked ~ nav #nav li:hover .submenu2 a,
#nav li:hover .submenu2 a{
  //padding-left: 0.5em;
  padding: 0em 0.71429em 0.14286em 1em;
}

//#toggle:checked ~ nav #nav li:hover .submenu2 a:hover,
//#nav li:hover .submenu2 a:hover{
//#toggle:checked ~ nav #nav ul a:hover .submenu2 a:hover,
//#nav ul a:hover .submenu2 a:hover{
 #toggle:checked ~ nav #nav li li.submenu-bg:hover .submenu2 a:hover,
 #nav li li.submenu-bg:hover .submenu2 a:hover{
  text-decoration: none;
  color: #FFF;
}

//bold when hover
#nav li.submenu-bg a:after{
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 1px;
  color: rgba(0, 0, 0, 0);
  overflow: hidden;
  visibility: hidden;
}

//Hover over submenu 3 li



}//48

/*@include breakpoint(computer-small) { //IE10
html[data-useragent*='MSIE 10.0'] #nav .submenu2{
//left: 0;
left: 0;
//width: 35em;
}
}*/



@include breakpoint(computer-small) {
#nav .submenu2{
//left:initial;
left: 100%;
top: -0.75em;
//border-left:thin solid #000;
}

}