//NAV FIXED when scrolled
.menu-fixed{
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 12;//2;
	background-color: $colly-startpage-lightblue;
	//background-color: rgba(0, 44, 101, 0.91);
}
	  .company-logo{
		  width: 6.5em; //124px;
		  //height:60px; 
		height: auto;
		//margin: 0 8px 8px 8px;
		//margin:0;
		//margin:0.5em 0 0.5em 0.5em;//0.5em 0 0 0.5em;
		margin: 0.5em 0 0 (10/16)+em;
		//float: left;
		display: block;
		z-index:2;
		position: relative;

		  a{
			  margin-top: 4px;
		  }

	  }

	  .company-logo-holder{
		  background-image:url('../images/colly-logo.svg');
		  background-repeat: no-repeat;
		  padding: 19% 0;
	  }

	  .no-svg .company-logo-holder{
		  background-image:url('../images/svg/colly-logo.png');
	  }

	//knuffar ner efterföljande content så att det inte börjar högst upp pga. att menyn är fixed och man vill behålla menyn innanför content diven pga. att man vill göra det enkelt att justere för footern. Använder padding istf margin eftersom det inte knuffar footern.
	.menu-fixed + div,
	.menu-fixed + figure,
	.menu-fixed + section,
	.menu-fixed + article{
		padding-top: 49px;
		//background-color: mistyrose;

		@include breakpoint(computer-small){
			padding-top: 68px;
			//background-color:aliceblue;
		}

		@include breakpoint(computer-medium) {
			padding-top: 116px;
			//background-color: white;
		}
	}

@include breakpoint(computer-small) {
.menu-fixed{
	//position: fixed;
	  //width: 100%;
	  margin: 0 auto;
	  display: block;
	  //background-color: $colly-darkblue;
	//background-color: rgba(0, 44, 101, 0.91);
	  //z-index: 1;

	  .bg-white{
		  padding-bottom: 0;
	  }

	  .company-logo{
		  //width: 10.3125em; //165px;
		  //height:3.75em; //60px; 
		//margin: 0 0.5em 0.5em 0.5em;
		float: left;
		@include borderbox;
		//padding:0.5em;

		//justerar för bredare colly meny
		width: 7em;
		height: 2.6875em; //43
		margin: 0;
		padding:0.75em 0.5em 0 0.5em;

		  a{
			  margin-top: 4px;
		  }
	  }

	  .company-logo-holder{
		  background-image:url('../images/colly-logo.svg');
		  background-repeat: no-repeat;
		  padding: 16% 0;//19% 0;
	  }

	  //mobile logo
	  #nav a.toplogo div, nav a.toplogo-mobile div{
		  display: none;
	  }

	  .mainNav{
		  //width: auto;
		  top: 1.375em; //20px;
		//top: 4.9375em; //79
		  height: 3.625em; //58px;
		//height: 1.125em; //58px;
		  float: left;

		  /*left: 50px;
		  position:absolute;
		  padding: 0;*/
		position: relative;
	  }

	  #toggle:checked ~ nav #nav, #nav{
		  top: 0;
	  }

	  #toggle:checked ~nav #nav a, #nav a{
		  //@include fontSizePX(14);
		@include fontSizePX(16);
		//padding:0 1em;
		//padding:0 0.75em;
		padding:0 0 1em 0;
	  }

	  //menu-second repositioning
	  .menu-second{
		  //top: -70px;
		  top: -58px;
		  //top: -12px;
		  position: absolute;
		  display: inline-block;
		  visibility: visible;
	  }

	  .wrapper-wide{
		  max-width: 1150px;
		  position: relative;
	  }
}//menu-fixed


.no-svg .menu-fixed .company-logo-holder{
	background-image:url('../images/colly-logo.png');
}


//IE10
/*html[data-useragent*='MSIE 10.0'] .menu-fixed .mainNav{
	position: absolute;
 }

html[data-useragent*='MSIE 10.0'] .menu-fixed .company-logo a{
	position: relative;
	top: 0;
}*/

html[data-useragent*='MSIE 10.0'] .menu-fixed #toggle:checked ~ nav #nav,
html[data-useragent*='MSIE 10.0'] .menu-fixed #nav{
	//top:3em;
	top: 1.5em;
}

}//computer-small

@include breakpoint(computer-medium) {

.menu-fixed .mainNav{
	top: (41/16)+em; //3.9375em; //4.9375em; //79
	  height: 1.125em; //58px;
}

.menu-fixed .company-logo{
	margin:1.25em 0.5em 1.25em 0.5em;

	  width: 11.3125em; //10.3125em; //165px;
	  height:3.75em; //60px; 
	padding:0.5em;
	//margin: 0 0.5em 0.5em 0.5em;
}


html[data-useragent*='MSIE 10.0'] .menu-fixed .mainNav{
	  top: 20px;
	  height: 58px;
	  float: left;

	  position: relative;
	  left: initial;
}
}

