
//Importing CSS breakpoints into JS, https://codepad.co/snippet/3O7cjCQJ
/**
 * These values will not show up in content, but can be 
 * queried by JavaScript to know which breakpoint is active.
 * Add or remove as many breakpoints as you like.
 */
	/*JS BREAKPOINTS----------------------------------------------------------*/
	//Importing CSS breakpoints into JS, https://codepad.co/snippet/3O7cjCQJ
	/**
	 * These values will not show up in content, but can be 
	 * queried by JavaScript to know which breakpoint is active.
	 * Add or remove as many breakpoints as you like.
	 */
	/*body:before {
		content: "smartphone";
		display: none; //Prevent from displaying. 
	}
	//@media (min-width: 700px) {
	@include breakpoint(mobile-portrait) { //320
		body:before {
			//content: "tablet";
			content: "320";
		}
	}
	@include breakpoint(mobile-landscape) { //480
		body:before{
			content: "480";
		}
	}
	@include breakpoint(mobile-large) { //640
		body:before{
			content:"640";
		}
	}
	@include breakpoint(tablet) { //768
		body:before{
			content:"768";
		}
	}
	@include breakpoint(tablet-landscape) { //1024
		body:before{
			content:"1024";
		}
	}
	@include breakpoint(computer-small) { //960
		body:before{
			content:"960";
		}
	}
	@include breakpoint(computer-medium) { //1140
		body:before{
			content:"1140";
		}
	}
	@include breakpoint(computer) { //1224
		body:before{
			content:"1224";
		}
	}
	@include breakpoint(computer-desktop) { //1680
		body:before{
			content:"1680";
		}
	}
	@include breakpoint(computer-large) { //1824
		body:before{
			content:"1824";
		}
	}
	*/

//Adding grid lines to the body, remove this in production
	/*body:after{
		//The first rgba color sets the line color
		background: linear-gradient(0, rgba(200,225,255,0.6) 1px, rgba(0, 0, 0, 0) 1px);
		//The second px value sets the height between lines
		background-size: 1px 1.5em;
		bottom: 0;
		margin-top: 0px;
		content: '';
		display: block;
		height: auto;
		left: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
		width: auto;
		z-index: 9999;
	}
	*/

//Wrappers
	.wrapper:after{
	 	content: "";
	    display: table;
	    clear: both;
	}

	.wrapper-1150,
	.wrapper-1920{
		max-width: 71.875em; //1150px
		width: 100%;
		margin-left: auto;
		margin-right:auto;
		display: block;
	}

	.wrapper-1920{
		max-width: 120em; //1920px
	}

//COLUMN WRAPPERS: col-50 etc
	.col-50{
		width: 100%;

		@include breakpoint(mobile-large) {
			width: 50%;
			float: left;
		}
	}//col-50

//HIDE or SHOW
	//VISUALLY-HIDDEN: https://medium.com/@matuzo/writing-css-with-accessibility-in-mind-8514a0007939, men kan även se <https://css-tricks.com/small-tweaks-can-make-huge-impact-websites-accessibility/> , removes visually but not for screenreaders
	.helper-hide {
		/* Remove the item from normal flow */
		position: absolute;
		/* Workaround for falsely pronounced, smushed text */
		white-space: nowrap;
		/* Set it to the smallest possible size (some screen readers ignore elements with zero height and width) */
		width: 1px;
		height: 1px;
		/* Hide overflowing content after resizing */
		overflow: hidden;
		/* Reset any property that may change the elements size */
		border: 0;
		padding: 0;
		/* Clipping defines what part of an element should be displayed. */
		/* Deprecated clip property for older browsers */
		clip: rect(0 0 0 0);
		/* clip-path for newer browsers. inset(50%) defines an inset rectangle that makes the content disappear.  */
		clip-path: inset(50%); 
		/* It seems like at the moment nobody is quite sure why margin: -1px is there. On top of that it seems to cause issues (see: https://github.com/h5bp/html5-boilerplate/issues/1985). */
		//margin: -1px; //förmodligen för att annars upptar elementet plats på små skärmar .popup-close evapp, lägger till left:-9999px;
		left:-9999px;
	}
	.helper-displaynone{
		display:none;
	}

	.hide-below-960{
		display: none;
		@include breakpoint(computer-small) {
			display: block;
		}
	}

	.hide-above-960{
		@include breakpoint(computer-small) {
			display: none;
		}
	}//hide-above-960
