//BODY:    //-----------------------------------------------------------------------------
	html{overflow-y:scroll;/*använder font-size 100% från normalizer*/}
	html, body, .container{
		height:100%;
	}

	body{
		//font-family: 'Industry-Light', Arial, Helvetica, sans-serif;
		@include fontFamily-regular();
		@include fontSizePX(16);
		-webkit-font-smoothing: antialiased;
		//https://css-tricks.com/forums/topic/font-face-weight-rendering-issue-on-osx/page/2/
		-moz-osx-font-smoothing: grayscale; //Setting this CSS property will fix the ‘too heavy’ font issue on Firefox on Mac:
		background-color: $colly-startpage-lightgrey;
	}

	body >.container{
		height:auto;
		min-height:100%;
	}
	.container{
		margin-bottom:-3.5em;
		@include borderbox;
		padding: 0 0 3.5em 0;
	}

//RO-TOGGLELINK: i h1 ska bara vara aktiv på mobiler
	.ro-togglelink{
		cursor: pointer;

		.plus, .minus{
			@include fontSizePX(32);
			float: right;
			font-weight: bold;
			position:relative;
			top: -(2/32)+em;
		}
	}

//COLLYS-TEXTBLOCK: lägger padding runt text så att det inte läggs på varje typografielement separat.
	.colly-textblock{
		padding:1em;
		box-sizing: border-box;

		a:hover, a:focus, a:active{
			color:$colly-startpage-darkblue;
		}

		p, ul, ol{
			color: $colly-startpage-middleblue;
		}

		ul, ol{
			padding: 0;
			//margin: 1em 0;
			margin: 0 auto 1.5em;
			line-height: 1.5;
			list-style-position: inside;
		}

		ul{
			list-style-type:none;

			li{
				//position:relative;
				//left: 1em;//(29/16)+em;
				padding: 0 0 0 1em;
			}

			li:before{
				content:'\2022';
				//padding-right: (8/16)+em;
				@include fontSizePX(24);
				line-height: (16/24);
				position:relative;
				top: (3/24)+em;
				left: -(16/24)+em; //-(29/24)+em;
				//margin-right: -(20/24)+em;
				margin-right: -(8/24)+em;
			}
		}//ul

		.colly-textblock-largetext p{
			@include fontSizePX(18);
		}

	}//colly-textblock