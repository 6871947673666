/*LAYOUT---------------------------------------------------------------*/	
	@mixin borderbox{
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
	}

	  %borderbox{
	    @include borderbox;
	  }

	  /*
	    Use: @extend %borderbox; but only outside of media queries. Inside media queries, use normal @include cover
	  */

	//FLEXBOX
	  @mixin flexbox{
	    display: -webkit-flex;
	    display: -moz-flex;
	    display: -ms-flex;
	    display: -o-flex;
	    display: flex;
	  }

	  %flexbox{
	    @include flexbox;
	  }

	  /*
	    Use: @extend %flexbox; but only outside of media queries. Inside media queries, use normal @include flexbox
	  */


	 //CLEARFIX
	 @mixin clearfix{
	 	&:after{
	 	content: "";
	    display: table;
	    clear: both;
		}
	 }

	 /*Codrops mail 2013-07-18*/
	  /* The star is here to prevent other browsers from reading and applying this rule. */

	  %clearfix{
	    @include clearfix;
	  }

	  /*
	    Use: @extend %clearfix; but only outside of media queries. Inside media queries, use normal @include clearfix
	  */

	/*COVER*/
  	@mixin cover{
    	-webkit-background-size:cover;
    	-moz-background-size: cover;
    	-o-background-size: cover;
    	background-size: cover;
    	-ms-behavior: url(backgroundsize.min.htc); //om man använder  https://github.com/louisremi/background-size-polyfill
  	}

  	%cover{
    	@include cover;
 	}

	@mixin marginPXtoEM($fontsize:16, $topsize:1, $rightsize:1, $bottomsize:1, $leftsize:1){
	  @if $topsize == $rightsize and $bottomsize == $rightsize and $topsize == $leftsize {
	    margin:(($topsize * 16)/$fontsize) + em;
	  }
	  @else if $topsize == $bottomsize and $rightsize == $leftsize{
	    margin:(($topsize * 16)/$fontsize) + em +' '+ (($rightsize * 16)/$fontsize) + em;
	  }
	  @else{
	    margin:(($topsize * 16)/$fontsize) + em +' '+ (($rightsize * 16)/$fontsize) + em +' '(($bottomsize * 16)/$fontsize) + em + ' ' + (($leftsize * 16)/$fontsize) + em;
	  }
	}

	@mixin paddingPXtoEM($fontsize:16, $topsize:1, $rightsize:1, $bottomsize:1, $leftsize:1){
	  @if $topsize == $rightsize and $bottomsize == $rightsize and $topsize == $leftsize {
	    padding:(($topsize * 16)/$fontsize) + em;
	  }
	  @else if $topsize == $bottomsize and $rightsize == $leftsize{
	    padding:(($topsize * 16)/$fontsize) + em +' '+ (($rightsize * 16)/$fontsize) + em;
	  }
	  @else{
	    padding:(($topsize * 16)/$fontsize) + em +' '+ (($rightsize * 16)/$fontsize) + em +' '(($bottomsize * 16)/$fontsize) + em + ' ' + (($leftsize * 16)/$fontsize) + em;
	  }
	}

/*STYLE---------------------------------------------------------------*/
	@mixin insetShadow{
	-moz-box-shadow:inset 0 0 5px 0 #ebdcbf;
	-webkit-box-shadow:inset 0 0 5px 0 #ebdcbf;
	box-shadow:inset 0 0 5px 0 #ebdcbf;
	}

	@mixin boxShadow($horizontal: 0, $vertical:0, $blur:5, $spread:0, $color:#000){ //blur=0 för helfylld
		-webkit-box-shadow: $horizontal +px $vertical +px $blur +px $spread +px $color;
		box-shadow: $horizontal +px $vertical +px $blur +px $spread +px $color;
	}

	@mixin boxShadowNone(){
		-webkit-box-shadow:none;
		box-shadow:none;
	}

	@mixin roundedCorners($radius: 0.5em){
		-webkit-border-radius:$radius;
		-moz-border-radius:$radius;
		border-radius:$radius;
	}

	@mixin roundedCornersTop($radius: 0.625em){
	-webkit-border-top-left-radius:$radius;
	-moz-border-top-left-radius:$radius;
	border-top-left-radius:$radius;
	-webkit-border-top-right-radius:$radius;
	-moz-border-top-right-radius:$radius;
	border-top-right-radius:$radius;
	}

	@mixin roundedCornersBottom($radius: 0.625em){
	-webkit-border-bottom-left-radius:$radius;
	-moz-border-bottom-left-radius:$radius;
	border-bottom-left-radius:$radius;
	-webkit-border-bottom-right-radius:$radius;
	-moz-border-bottom-right-radius:$radius;
	border-bottom-right-radius:$radius;
	}

	@mixin roundedCornersTopLeft($radius: 0.625em){
	-webkit-border-top-left-radius:$radius;
	-moz-border-top-left-radius:$radius;
	border-top-left-radius:$radius;
	}

	@mixin roundedCornersBottomLeft($radius: 0.625em){
	-webkit-border-bottom-left-radius:$radius;
	-moz-border-bottom-left-radius:$radius;
	border-bottom-left-radius:$radius;
	}

	@mixin roundedCornersTopRight($radius: 0.625em){
	-webkit-border-top-right-radius:$radius;
	-moz-border-top-right-radius:$radius;
	border-top-right-radius:$radius;
	}

	@mixin roundedCornersBottomRight($radius: 0.625em){
	-webkit-border-bottom-right-radius:$radius;
	-moz-border-bottom-right-radius:$radius;
	border-bottom-right-radius:$radius;
	}

	 @mixin transparent($transparency){
	    //-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=$transparency)"; // IE8
	    /* This works in IE 8 & 9 too */
	    /* ... but also 5, 6, 7 */
	    filter: alpha(opacity=$transparency); // IE 5-7
	    opacity: $transparency;
	  }

  /*Transparent*/
  //https://css-tricks.com/snippets/css/cross-browser-opacity/
  @mixin opacity($opacity:0.5, $ms-opacity:$opacity * 100, $ie8:"progid:DXImageTransform.Microsoft.Alpha(Opacity="){
    /* IE 8 */
    -ms-filter: $ie8 + $ms-opacity +')';

    /* IE 5-7 */
    filter: alpha(opacity= $ms-opacity);

    ///* Netscape */ kommenterar bort dessa eftersom de känns så gamla
    //-moz-opacity: $opacity;

    ///* Safari 1.x */ kommenterar bort dessa eftersom de känns så gamla
    //-khtml-opacity: $opacity;

    /* Good browsers */
    opacity: $opacity;
  }



/*TYPOGRAPHY------------------------------------------------------------------------------*/
	@mixin fontSizePX($size:16, $line: $size * 1.2){
		font-size:   $size + px;
		font-size:   ($size / 16) + rem;
	}

	@mixin lineHeightPX($size:16, $line:($size / 16) * 1.25){
		line-height:$line;
	}

	@mixin ellipsis{
		white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    -o-text-overflow: ellipsis;
	}

	@mixin hyphernate{
		-ms-word-break: break-all;
            word-break: break-all;
            // Non standard for webkit
            word-break: break-word;
            -webkit-hyphens: auto;
               -moz-hyphens: auto;
                    hyphens: auto;
            /*The Hyphenate-Before or -after sets a minimum number of characters before or 
            after the hyphenation. And hyphenate-lines how many lines can be hyphenated in a row.
            As you may have guessed, these are Webkit only.*/
		-webkit-hyphenate-before: 2;
    	-webkit-hyphenate-after: 3;
        	    hyphenate-lines: 3;
	}

	//http://codepen.io/chriscoyier/blog/some-mini-sass-mixins-i-like
	@mixin word-wrap() {
	  word-break:     break-word;
	  -webkit-hyphens: auto;
	  -moz-hyphens:    auto;
	  hyphens:         auto;
	}	


	//Utilizing system font families:
	//http://stackoverflow.com/questions/19691530/valid-values-for-androidfontfamily-and-what-they-map-to
	//http://iosfonts.com/
	//http://pieroxy.net/blog/2014/10/11/the_quest_for_a_condensed_web_font.html
	@mixin fontFamily-light() {
		font-family:/*ios*/ "HelveticaNeue-Light", /*ios*/ "HelveticaNeue", /*android system*/ sans-serif-light, /*android*/ "Roboto", /*fallbacks*/Arial, Verdana, "Trebuchet MS", sans-serif;
		font-weight:300;
	}

	@mixin fontFamily-light-italic() {
		font-family: /*ios*/ "HelveticaNeue-LightItalic", /*ios*/ "HelveticaNeue", /*android system*/ sans-serif-light, /*android*/ "Roboto", /*fallbacks*/Arial, Verdana, "Trebuchet MS", sans-serif;
		font-weight:300;
		font-style:italic;
	}

	@mixin fontFamily-regular() {
		font-family:/*ios*/ "HelveticaNeue", /*android*/ "Roboto", /*fallbacks*/ Arial, Verdana, "Trebuchet MS", sans-serif;
		font-weight: 400;		
	}

	@mixin fontFamily-regular-italic() {
		font-family:/*ios*/ "HelveticaNeue-Italic",  /*ios*/ "HelveticaNeue", /*android*/ "Roboto", /*fallbacks*/ Arial, Verdana, "Trebuchet MS", sans-serif;
		font-weight: 400;
		font-style: italic;
	}

	@mixin fontFamily-medium() {
		font-family:/*ios*/ "HelveticaNeue-Medium",  /*ios*/ "HelveticaNeue", /*android system*/ sans-serif-medium, /*android*/ "Roboto", /*fallbacks*/Arial, Verdana, "Trebuchet MS", sans-serif;
		font-weight:500;
	}

	@mixin fontFamily-medium-italic() {
		font-family: /*ios*/ "HelveticaNeue-MediumItalic", /*ios*/ "HelveticaNeue", /*android system*/ sans-serif-medium, /*android*/ "Roboto", /*fallbacks*/Arial, Verdana, "Trebuchet MS", sans-serif;
		font-weight:500;
		font-style:italic;
	}

	@mixin fontFamily-bold() {
		font-family:/*ios*/ "HelveticaNeue-Bold", /*ios*/ "HelveticaNeue", /*android*/ "Roboto", /*fallbacks*/Arial, Verdana, "Trebuchet MS", sans-serif;
		font-weight:700;
	}

	@mixin fontFamily-bold-italic() {
		font-family: /*ios*/ "HelveticaNeue-BoldItalic",  /*ios*/ "HelveticaNeue", /*android*/ "Roboto", /*fallbacks*/Arial, Verdana, "Trebuchet MS", sans-serif;
		font-weight:700;
		font-style:italic;
	}



/*MEDIAQUERIES------------------------------------------------------------------------------*/
@mixin breakpoint($media) {
  @if $media == mobile-portrait { /*320*/
    @media only screen and (max-width: $break-mobile-small) { @content; }
  }
   @else if $media == mobile-landscape {/*480*/
    @media only screen and (min-width: $break-mobile-medium) { @content; }
  }
  @else if $media == mobile-large {/*640*/
    @media only screen and (min-width: $break-mobile) { @content; }
  }

  @else if $media == tablet { /*768*/
    @media only screen and (min-width: $break-tablet) { @content; }
  }

  @else if $media == tablet-landscape { /*1024*/
    @media only screen and (min-width: $break-computer) { @content; }
  }

  @else if $media == computer-small { /*960*/
    @media only screen and (min-width: $break-computer-small) { @content; }
  }

  @else if $media == computer-small-tablet { /*960 and ipad landscape*/
    @media only screen and (min-width: $break-computer-small-tablet), (min-device-width : 48em) and (max-device-width : 64em) and (orientation : landscape) { @content; }
  }

  @else if $media == tablet-portrait { /*ipad portrait*/
  	@media only screen and (min-device-width : 48em) and (max-device-width : 64em) and (orientation:portrait) { @content; }
  }

  @else if $media == tablet-landscape { /*ipad landscape*/
  	@media only screen and (min-device-width : 48em) and (max-device-width : 64em) and (orientation:landscape) { @content; }
  }

  @else if $media == computer-medium { /*1140*/
    @media only screen and (min-width: $break-computer-medium) { @content; }
  }

  @else if $media == computer { /*1224*/
    @media only screen and (min-width: $break-computer-large) { @content; }
  }

  @else if $media == computer-desktop { /*1680*/
    @media only screen and (min-width: $break-computer-desktop) { @content; }
  }

   @else if $media == computer-large { /*1824*/
    @media only screen and (min-width: $break-computer-xl) { @content; }
  }
}

/*RETINA IMAGES------------------------------------------------------------*/
	@mixin image-2x($image, $width, $height) {
	  @media (min--moz-device-pixel-ratio: 1.3),
	         (-o-min-device-pixel-ratio: 2.6/2),
	         (-webkit-min-device-pixel-ratio: 1.3),
	         (min-device-pixel-ratio: 1.3),
	         (min-resolution: 1.3dppx) {
	    /* on retina, use image that's scaled by 2 */
	    background-image: url($image);
	    background-size: $width $height;
	  }
	}

/*VH, VW, VMIN, VMAX-------------------------------------------------------*/
/*IOS < 8 doesn't support vh, vw etc*/
    /*https://gist.github.com/pburtchaell/e702f441ba9b3f76f587*/
    /*https://gist.github.com/BenMorel/e9e34c08360ebbbd0634*/
    /**
       * Fix for vw, vh, vmin, vmax on iOS 7.
       * http://caniuse.com/#feat=viewport-units
       *
       * This fix works by replacing viewport units with px values on known screen sizes.
       *
       * iPhone 6 and 6 Plus cannot run iOS 7, so are not targeted by this fix.
       * Target devices running iOS 8+ will incidentally execute the media query,
       * but this will still produce the expected result; so this is not a problem.
       *
       * As an example, replace:
       *
       *   height: 50vh;
       *   font-size: 5vmin;
       *
       * with:
       *
       *   @include viewport-unit(height, 50vh);
       *   @include viewport-unit(font-size, 5vmin);
       */
      @mixin viewport-unit($property, $value) {
        #{$property}: $value;

        $unit: unit($value);

        @if (index((vw, vh, vmin, vmax), $unit) != null) {
          $devices: (
            (768px, 1024px), // iPad (all versions)
            (320px, 480px),  // iPhone 4
            (320px, 568px)   // iPhone 5, 5C, 5S
          );

          @each $device in $devices {
            $device-width: nth($device, 1);
            $device-height: nth($device, 2);

            $device-query: "only screen and (-webkit-min-device-pixel-ratio: 1)";
            $device-query: "#{$device-query} and (device-width: #{$device-width})";
            $device-query: "#{$device-query} and (device-height: #{$device-height})";

            $percent: $value / ($value * 0 + 1); // see https://github.com/sass/sass/issues/533

            $percent-width: $device-width * $percent / 100;
            $percent-height: $device-height * $percent / 100;

            @if ($unit == vmin or $unit == vmax) {
              @media #{$device-query} {
                #{$property}: if($unit == vmin, $percent-width, $percent-height);
              }
            }
            @else {
              @media #{$device-query} and (orientation: portrait) {
                #{$property}: if($unit == vw, $percent-width, $percent-height);
              }

              @media #{$device-query} and (orientation: landscape) {
                #{$property}: if($unit == vw, $percent-height, $percent-width);
              }
            }
          }
        }
      }