//COLLY-BTN-OUTLINE: t.ex. på startsidan i colly-mixedboxes-----------------------
    .colly-btn-outline{
        box-shadow: none;
        border:1px solid #FFF;
		//border:thin solid #FFF;
        @include fontFamily-bold;
        color: #FFF;
        background-color:transparent;
        @include borderbox;
        //@include fontSizePX(18);
		@include fontSizePX(14);
        //@include paddingPXtoEM(18,0.625,0.875,0.625,0.875);
        //@include paddingPXtoEM(18,0.625,1.125,0.625,1.125);
		@include paddingPXtoEM(18,0.625,1.125,0.625,1.125);
		border-radius: 0;
		
		transition: all 0.3s;

		//transition: border-color 0.4s, color 0.4s;
		position:relative;
		display:block;
		z-index: 1; //så att det inte blir en rand
    	-webkit-backface-visibility: hidden;
    	-moz-osx-font-smoothing: grayscale;
		//vertical-align:middle;

        &:hover, &:focus, &:active{
            cursor:pointer;
			border:1px solid #FFF; //$colly-startpage-darkblue; //$colly-yellow;
			color: $colly-startpage-darkblue;
        }

		&:before{
			/*content:'';
			position:absolute;
			top: 0;
			left: 0;
			width:100%;
			height:100%;
			background-color:$colly-yellow;
			z-index: -1;
			opacity:0;
			transform: scale3d(0.5, 1, 1);
			transition:transform 0.4s, opacity 0.4s;
			transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);*/
			content: '';
    		position: absolute;
    		z-index: -1;
			//transform: translateX(-50%) translateY(-50%);
			transform: translate(-50.1%, -50.1%);
			width: 0;
			height: 103%;
			top: 50%;
			left: 50%;
			background:#FFF; //$colly-startpage-darkblue; // $colly-yellow;
			opacity: 0;
			transition: all 0.3s;
		}

		&:hover:before, &:focus:before, &:active:before{
			opacity:1;
			//transform:translate3d(0,0,0);
			width: 103%;//103%;
		}
	}//colly-btn-outlined
	
//COLLY-BTN-TRANSPARENT: används på colly startpage som knapp i puffar på små skärmar
	.colly-btn-transparent{
		border: (2/16)+em solid #FFF;
		border-radius: 0;
		box-shadow:none;
		background-color: #FFF;
		background-color: rgba(255,255,255,0.6);
		color: $colly-startpage-darkblue;
		font-weight:bold;
		padding:(6/16)+em (10/16)+em;

		&:hover, &:focus, &:active{
			background-color: #FFF;
		}
	}//colly-btn-transparent