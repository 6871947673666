/*COLORS*/
$lightpink: #ffeee0;

//collyspecifika färger
$colly-darkerblue:#001b46; //rgba(0, 27, 70, 0.9)
$colly-darkblue: #002c65; //background-color: rgba(0, 44, 101, 0.91);
$colly-lightblue: #00599a; //rgba(0,89,154,0.9);
$colly-coral: #ed5350; //rgba(237, 83, 80, 0.91);
$colly-yellow: #db813e; //rgba(219, 129, 62, 0.91);
$colly-lightgrey: #dcddde;

$colly-startpage-lightlightblue:#87d6ff;
$colly-startpage-lightblue: #0089d0;
$colly-startpage-darkblue: #042967;
$colly-startpage-middleblue:#1262a4;
$colly-startpage-lightgrey:#eceded;

/*BREAKPOINTS-------------------------------------------*/
	$break-mobile-small:20em; /*320px*/
		$break-mobile-small-break:20.0625em; /*321px*/
		$break-mobile-medium:30em; /*480*/
	$break-mobile:40em; /*640px*/
	$break-tablet:48em; /*768px*/
		$break-computer-small:60em; /*960px*/
		$break-computer-small-tablet:60em; /*960px*/
	$break-computer:64em; /*1024px*/
		//$break-computer-large:71em: /*1136px*/
	$break-computer-medium:71.25em; /*1140px*/
	$break-computer-large:76.5em; /*1224px*/
	$break-computer-desktop:105em; //1680px
	$break-computer-xl:114em; /*1824px*/

/*LIST OF Z INDEX---------------------------------------*/
 /*



 */

 		/*Targetting IE10-------------------------------------------------------*/
		/*html[data-useragent*='MSIE 10.0'] label.label-select:after {*/

/*TYPOGRAPHY---------------------------------------------*/
/*
	h1 24
	h2 21
	h3 
	h4
	p
	smallprint
*/