//@SUPPORT stödjs inte av gulp media query combine. Lägg till det manuellt.
//I den här filen kopierar jag över de @supports block som används, så att de blir enklare att hitta och lägga in.
//CSS inkluside prefixes och @supports block finns i screen-prefix.css

    //css som inte är flex, som måste till bara för browsers som stödjer flexbox, justerar så att textarean kan stretcha. Notera att det kan innebära att IE11 stödjer flexbox men inte @supports, så nedanstående kanske behövs i en extern ie11.css fil.
	@supports( (display:flex) or (display: -webkit-flex) or (display: -webkit-flex) or (display: -moz-flex) or (display: -ms-flex) or (display: -o-flex) ){
        .module-puff-1-list,
		.module-puff-2-list,
		.module-puff-3-list,
		.module-puff-4-list{
			.one .wrapper .hidden-text,
			.two .wrapper .hidden-text,
			.three .wrapper .hidden-text,
			.four .wrapper .hidden-text{
				display:flex;
				margin:auto;
                justify-content: center;
                
                &.hide-below-960{
                    display: none;

                    @include breakpoint(computer-small) {
                        display:flex;
                    }
                }

                @include breakpoint(computer-small) {
                    &.hide-above-960{
                        display:none;
                    }
                }//computer-small

			}//hidden-text
        }


        
        //4 puffar i rad ska ha centrerad knapp som inte upptar hela bredden på safari
		.module-puff-4-list /*.one*/ .wrapper .hidden-text  .colly-btn-outline,
		.module-puff-4-list /*.two*/ .wrapper .hidden-text  .colly-btn-outline,
		.module-puff-4-list /*.three*/ .wrapper .hidden-text  .colly-btn-outline,
		.module-puff-4-list /*.four*/ .wrapper .hidden-text .colly-btn-outline{
			//align-items: center;
			align-self:center;
		}

		//.colly-mixedboxes--hoverimg p,
		.module-puff-1-list p,
		.module-puff-2-list p,
		.module-puff-3-list p,
		.module-puff-4-list p{
			display:flex;
			transform:none;
			flex-direction:column;
			margin:auto;
			//align-items:center;
			justify-content:center;
			position:static;
		}

        //vänsterställa knappar och rubriker
		//.colly-mixedboxes--hoverimg .colly-btn-outline,
		//.colly-mixedboxes .colly-mixedboxes--hoverimg .col-50 + .col-50 .colly-btn-outline,
		.module-puff-1-list .colly-btn-outline,
		.module-puff-3-list .colly-btn-outline,
		.module-puff-2-list .colly-btn-outline,
		.module-puff-4-list .colly-btn-outline{
			margin-left: 0;
			align-self:flex-start;
		}
        
        //.colly-mixedboxes--hoverimg .hidden-text-title,
		.module-puff-1-list .hidden-text-title,
		.module-puff-2-list .hidden-text-title,
		.module-puff-3-list .hidden-text-title{
			align-self:flex-start;
		}
    }//supports

        
//GRID SUPPORTS
    @supports(display:grid) {
        

        @include breakpoint(tablet) {
           
        }//tablet



        @include breakpoint(computer-small) {
        
        }//computer-small
    } //grid