footer {
  bottom: 0;
  min-height: 3.5em;
  position: relative;
  margin-top: 0;
  z-index: 10;
  width: 100%;
  //background-color:green;
  //color:yellow;

  a[href^="tel"] {
    color: $colly-startpage-middleblue;
    text-decoration: none;
  }

  ul {
    margin: 0;
  }
}

//COLLYS-FOOTERLIST: footern på colly startsida
.collys-footerlist {
  color: $colly-startpage-middleblue;
  list-style-type: none;
  text-align: center;
  padding: 1em 0;

  li {
    display: inline-block;
    @include fontSizePX(14);

    @include breakpoint(computer-small) {
      @include fontSizePX(14);
    }

    &:after {
      content: "|";
      display: inline-block;
      padding: 0 0 0 (4/16)+em;//0 0.25em;
    }
  } //li

  li:last-child:after {
    content: "";
  }
} //collys-footerlist
