/*-------------------------------------------------------------------------
NAV
---------------------------------------------------------------------------*/
.mainNav{
  //@include fullwidthCentered;
  margin:0 auto;
  width:100%;
  background-color:$colly-startpage-lightblue;
  height:3.0625em;//2.5em;
  color:orange;
  z-index: 1;
  position: absolute;
  top: 0;

  //@include breakpoint(tablet) {
  //@include breakpoint(computer-small-tablet) {
    @include breakpoint(computer-small) {
    height: 4.25em;
    //för att det ska gå att klicka på länkar i maxislider   
    //top: initial;
    //top: 3em;
    top: 1.75em;
    position: relative;
    //@include span-columns(9 omega, 12);
    //@include span-columns(10 omega, 12);
    width: 100%;
    width:calc(100% - 12.375em);// 182px);
    @include borderbox;
    //padding: 0 1em;
  }

  @include breakpoint(computer-medium) {
    padding: 0 1em;
  }

}

/*button in main nav*/
  /*.mainNav input[type="submit"] {
    background-color: transparent;
    border:none;
    @include fontSizePX(10);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -0.1em;
    padding: 0 0.3333em;
  }*/

//SVG Menu Icon, note that to style with hover, you need to have the svg inlined
  .menu-icon{
    height: 2.5625em;//41px;
    width: 2em;
    margin: 0 auto;
    display: block;
    @include borderbox;
    padding: 1em 0 0 0;
  }

  .svgmenu{
    fill:#FFF;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition:all .2s ease-in-out;
  }

//SVG Menu Icon Fallback http://css-tricks.com/using-svg/
  .fallback-svgmenu{
    display:none;
    height: 41px;
    width:70px;
  }

  .no-svg .fallback-svgmenu{
    //background-image:url('../img/menu-burger.png');
    background-image:url('../images/menu-burger-32x18.png');
    background-repeat: no-repeat;
  }

  .no-svg .btnToggle:hover .fallback-svgmenu{
    //background-image:url('../img/menu-burger-hover.png');
    background-image:url('../images/menu-burger-32x18.png');
  }


//FAQ toggle
.toggledetails{display:none;}
#close{font-size:0.5em;color: transparent;}//to prevent the close white link from being too large

//TOGGLE classes
  //TOGGLE buttons
  #details{display:none;}

  //DAVID tycker det verkar mycket smartare med en klass
  .toggledetails{
    display:none;
    &:after{@include clearfix;}

  }

//TOGGLE
.btnToggle {
  position: absolute;
  top:0;//6.5em;//0;
  right:0;
  z-index: 100;
  //padding: 0.25em 2%;
  color: #fff;
  
  //background-color: $darkGreen;
  background-color:$colly-startpage-lightblue;//$middleGreen;
  width: 4.375em; //70px;
  height:3.0625em;//49px
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  transition:all .2s ease-in-out;
}
  .btnToggle:hover {
    //background-color: #FFF;
    background-color:$colly-darkerblue;//$darkGreen;
    //color: $darkGreen;
    color: #FFF;
    cursor: pointer;
  }

#nav {
  position: absolute;
  top: 3em;//9.5625em;//3.0625em;//2.53em;
  z-index: 10;
  width: 100%;
  list-style: none;
  text-align: left; }


  #nav a {
    height: 0;
    line-height: 0;
    display: block;
    border-bottom-width: 0;
    background: $colly-darkerblue;//$darkGreen;//#444;
    //padding: 0 0 0 5%;
    //text-align: right; //ser till så att texten i mobila menyn är högerställd
    padding: 0 5%; //ser till så att det blir lite padding när man har mobil meny högerställd
    overflow: hidden;
    color: #FFF;
    text-decoration: none;
    //text-transform: uppercase;
    }

    #nav li{
      position:relative;
    }

ul{margin:0;padding:0;}

#toggle {
  position: absolute;
  top: -9999px;
  left: -9999px; }

#toggle:checked ~ nav #nav {
  z-index: 101;
 }
  #toggle:checked ~ nav #nav a {
    line-height: 3em;
    height: 3em;
    border-bottom: 1px solid $colly-startpage-middleblue;//$middleGreen;
    position: relative;
    z-index: 1;

    -webkit-transition: height 0.25s;
    -moz-transition: height 0.25s;
    -ms-transition: height 0.25s;
    -o-transition: height 0.25s;
    transition: height 0.25s;
  }
    #toggle:checked ~ nav #nav a:hover {
      background:#FFF;
      color:$colly-startpage-darkblue;//#FFF; //$colly-yellow;
    }//$middleGreen; }

    #toggle:checked ~ nav #nav a:hover + a.btn-plus { //kan inte vara vit eftersom det är samma färg som på bakgrunden i raden ovan
      color: $colly-startpage-darkblue;//#FFF; //$colly-yellow;
    }

  #toggle:checked ~ nav #nav #close {
    position: relative;
    z-index: 0; }
    #toggle:checked ~ nav #nav #close label {
      background: transparent;
      border-bottom: 0;
      position: absolute;
      top: -101em;
      bottom: -101em;
      left: 0;
      right: 0;
      z-index: 0; }


//sub
      #toggle:checked ~ nav #nav a.btn-plus{
          //height: 3.0625em;//3.125em;
          padding:0 0 0 0 !important;
          float:right;
          //top:-3.0625em;//-3.125em;
          @include borderbox;
          width:17%;
          text-align: center; 
          border-left: thin solid grey;

         //se till så att inte plusknappen syns när hela undermenyn animeras ut
          background-color: transparent;
          border-bottom:transparent;
          border-left: thin solid transparent;
          
          position: absolute;
          top: 0;
          right: 0;
      }

      #toggle:checked ~ nav #nav a.btn-plus:hover{
        background-color: #FFF; //$colly-yellow;
        color: $colly-startpage-darkblue; //#FFF;
      }

  #toggle:checked ~ nav #nav .last a.btn-plus{
    height: 3.125em; //51
    //border-bottom: 0.em solid grey;
  }

    //sub
    #toggle:checked ~ nav #nav li a.after-submenu{
      overflow: visible;
    }

    //ser till så att submenyer inte får listbollar
    .submenu, .submenu2{
      list-style-type: none;
    }

  //@media screen and (min-width: 48em) {
  //@include breakpoint(computer-small-tablet) {
    @include breakpoint(computer-small) {
    .mainNav{
      background-color: transparent;
    }

    .submenu-border, .submenu--border{ //ser till så att submenu får en bg färg på hela bredden, även om det redan är ett grått band som på sidan news
      border-bottom:2.25em solid grey;
    }

    //sub
    #nav li a.btn-plus{
      display: none !important;
      visibility: hidden;
    }

    .btnToggle {
      display: none; }

    .mainNav nav{
      max-width: 71.875em;//1150px
      margin: 0 auto;
    }

    html[data-useragent*='MSIE 10.0'] .mainNav{ //IE10
        position: relative;
    }

  #toggle:checked ~ nav #nav,
  #nav {
    //top: initial;
    top: 1em;
    -webkit-box-shadow: 0 0 0 0;
    -moz-box-shadow: 0 0 0 0;
    -ms-box-shadow: 0 0 0 0;
    -o-box-shadow: 0 0 0 0;
    box-shadow: 0 0 0 0;
    text-align: left;
    max-width: 60em;//960px;
    margin: 0 auto 0 auto;
    @include borderbox;

    width: auto;

    //Colly startsida
    text-align:center;
    position:relative;
  }


    #toggle:checked ~ nav #nav li,
    #nav li {
      //display: inline;
      //display: inline-block;
      //display: block;
      //float: left;
      //padding-bottom: 0.25em; tar bort så att det inte blir glapp för tredje menyn
      //padding-right: 2%;
      //@include borderbox;
      //float: right;

      //padding: 0 1em 0 0;
      position:relative;
      //border-right:1px solid #FFF;
      padding:0 0.375em; //1.25em;
      text-align: center;
      //height: 1em;
      //padding-bottom:1em; //se till så att man kan hovra ner till tredje menyn

      //Colly startsida
      display:inline-block;
    }

    //ta bort border-right på sista li
    #toggle:checked ~ nav #nav li:nth-last-child(3),
    #nav li:nth-last-child(3) {
      border:none;
    }

    #toggle:checked ~ nav #nav li.current a,
    #nav li.current a {
      text-decoration: underline;
    }

    #toggle:checked ~ nav #nav li.current .submenu a,
    #nav li.current .submenu a{ //ser till så att det inte blir underline på alla a i li
      text-decoration: none;
    }

    #toggle:checked ~ nav #nav li.current .submenu a:hover,
    #nav li.current .submenu a:hover{ //ser till så att det  blir underline på alla a i li om man gör hover i submenu
      text-decoration:underline;
    }

    #toggle:checked ~ nav #nav a,
    #nav a {
      line-height: 1.4;//1em;
      height:1.375em;//1.125em;
      display: inline-block;//inline;
      //display: inline;
      //float: right;
      background: transparent;
      //padding: 0 0.5625em 0 0.5625em; //padding-bottom 22px
      padding: 0;
      border-bottom: 0;
      font-weight: 300;
      //font-size: 18px;
      //@include fontSizePX(18);
      //@include fontSizePX(17);
      //@include fontSizePX(16);
      @include fontSizePX(14);
      //width:6.75em;
      text-align: center;
      //border-right: thin solid #FFF;
      color:#FFF;
      white-space:nowrap; //https://css-tricks.com/almanac/properties/w/whitespace/
      position: relative;
      top: -2px;

    }

    //#toggle.checked ~ nav #nav li:nth-last-child(2) a:after,
    //#nav li:nth-last-child(2) a:after{


    //#nav a:hover, #nav a:active{text-decoration: underline;}//$orange;}

    #toggle:checked ~ nav #nav #close,
    #nav #close {
      display: none; }

      #nav .submenu li:first-of-type a{
        border-left: none;
      }

    }//end 48

    @include breakpoint(computer-medium) {


      #nav li.middle{
        width: auto;//26%;
      }

      #nav li.last{
        width: auto;//32%;
      }

      #toggle:checked ~ nav #nav li,
      #nav li {
        padding:0 1.25em;
      }

    }//computer-medium


    //sub
    #toggle:checked ~ nav #nav li a.after-submenu{
      overflow: visible;
    }

    


//SUBMENU

  #toggle:checked ~ nav #nav .submenu li,
    #toggle:checked ~ nav #nav .submenu a {
      height: 0;
      line-height: 0;
    }
    #toggle:checked ~ nav #nav .submenu.open li,
    #toggle:checked ~ nav #nav .submenu.open a {
      height: 3em;
      line-height: 3em;
      overflow: visible; //EDENVIK ser till så att första submenylänken är lika bred som de andra
      border-bottom:thin solid $colly-startpage-middleblue; //#949494;
      &:hover{
      //background-color:lighten(#555, 10%);
      background-color:$colly-startpage-lightblue; //$colly-yellow;// #FFF;
        }
      }

      #toggle:checked ~ nav #nav .submenu.open a:hover + a.btn-plus {
        background-color: $colly-startpage-lightblue;
        color: #FFF;
      }


    #toggle:checked ~ nav #nav .submenu a {
      padding-left: 7%;
      background-color:$colly-darkblue;//#d3d3d3;
      color: #FFF;


      //se till så att det inte blir genomskinliga gaps i undermenyn när den fälls ut
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
    }

    #toggle:checked ~ nav #nav li:hover .submenu a:hover,
    #toggle:checked ~ nav #nav li:hover .submenu a:active,
    #nav li:hover .submenu a:hover,
    #nav li:hover .submenu a:active{
            color:#FFF;
          }


.multi:after {
  content: " \0025Bc";
  font-size: 0.5em; }      

//@media screen and (min-width: 48em){/*här byts mellan mobilnav och vanlig nav
//@include breakpoint(computer-small-tablet) {
  @include breakpoint(computer-small) {

//sub
    #toggle:checked ~ nav #nav .submenu, //Gör så att toggle för mobile göms på stor skärm
    #nav .submenu {
      display:none;
      //display: block; //Storesupport
    }

    #toggle:checked ~ nav #nav li:hover .submenu,
    #nav li:hover .submenu {
            display:block;
            background-color: $colly-startpage-darkblue;
            //background-color: rgba(0, 44, 101, 0.91);
            background-color: rgba(4, 41, 103, 0.91);
            position: absolute;
            //min-width: 89%;
            //min-width: 100%;
            top: 2.875em; //1.875em; //30px; //1em;
            min-width: 7.125em;
          }

          //ie10
          html[data-useragent*='MSIE 10.0'] #toggle:checked ~ nav #nav li:hover .submenu,
          html[data-useragent*='MSIE 10.0'] #nav li:hover .submenu {
            top: 27px;
          }
              
    

    #nav li.submenu-bg:hover a,
    #nav li.submenu-bg:focus a,
    #nav li.submenu-bg:active a,
    #nav li.submenu-bg a.current{
      //color:$colly-yellow;
      @include fontFamily-bold;
    }

      #nav li.submenu-bg:hover .submenu a,
      #nav li.submenu-bg:focus .submenu a,
      #nav li.submenu-bg:active .submenu a{
        text-decoration: none;
      }

  #nav li.submenu-bg:hover .submenu a{
    color:#FFF;
  }

  #nav li.submenu-bg:hover .submenu li:hover a{
    //color:$colly-yellow;
    color:$colly-startpage-lightlightblue;
  }

   #nav .submenu{
    //position: absolute;
    z-index: -1;
    text-align: left;//right;
    left:3.8125em;//61px;
    //margin-left: -0.25em;
    //padding-left: 0.1875em;
    padding-left: 0.625em;
    margin-top: -0.625em;
    //padding-top: 0.35em;//1em;
    padding-top: 0.75em; //1.125em;
    padding-bottom: 0.75em; //0.375em;
  }

    //se till så att andra nivån inte får li height 1em
    #nav li .submenu li{
      height:auto;
    }


        #toggle:checked ~ nav #nav li .submenu a,
        #nav li .submenu a{
          //@include fontSizePX(13);
          @include fontSizePX(14);
          color: #FFF;
          //@include paddingPXtoEM(13,0,0.625,0,0);
          //@include paddingPXtoEM(14,0,0.625,0,0);
          @include paddingPXtoEM(14,0,0.625,0.125,0.125);
          display: block;
          text-align: left;
          //padding: 0 0.76923em 0.125em 0.125em;
          height:auto;
          line-height:1.75;
        }

        //Om man vill ha något tecken framför submenulist 3
        //#nav li .submenu a:after{

      #nav .submenu a.link-margin-largescreen{margin: 0.3125em 0 -0.3125em 0;color:#333;}

    #toggle:checked ~ nav #nav li:hover .submenu a:hover,
    #nav li:hover .submenu a:hover{
            color:#FFF;
            //text-decoration: underline;
          }

  #nav input[type="search"], #nav input[type="text"] {
    position: relative;
    top: -0.285714em;//-0.25em;
    @include borderbox;
    @include fontSizePX(14);
    @include paddingPXtoEM(14,0.125,0.25,0.125,0.25);
  }

  #nav input[type="submit"]{
    position: relative;
    top: -0.5em;//-5px;
  }


  //NY meny

  .submenu-bg:hover{
    .submenu{
      display:block;
    }
  }

  #nav li.submenu-bg ul.submenu li{
    float: none;
    display: block;
    padding: 0;
    border:none;
  }


}//48

@include breakpoint(computer-small) { //IE10
  html[data-useragent*='MSIE 10.0'] #nav .submenu-bg{
    position: relative;

  }

}


@include breakpoint(computer-small) {
   #nav .submenu{
    //left: 0;
    left:inherit;
    left:initial;
    //border-left:thin solid #000;
  }

}

@media screen and (min-width: 52.5em) {
    #nav label a {
      padding: 0; }
}