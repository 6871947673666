//H1
h1 {
  font-weight: normal;
  color: $colly-startpage-darkblue;
  @include fontSizePX(28);
  margin: (8/28)+em 0;

  /*margin: 1.64286em 0em 0.85714em 0em;
  font-size: 28px;
  font-size: 1.75rem;*/

  @include breakpoint(computer-small) {
    @include fontSizePX(32);
    //margin: 1.27778em 0em 0.66667em 0em;
    margin: (16/32)+em 0 (12/32)+em;
  }
}//h1

//H2, H3, H4, H5, H6
  h2, h3, h4, h5, h6{
    font-weight: bold;
    @include fontSizePX(16);
    margin: 1em auto 0;
    color: $colly-startpage-darkblue;
  }

//P
p {
  @include fontSizePX(16);
  margin: 0 auto 1.5em;
  line-height: 1.5;
}

//A
a{
  color: inherit;
}